:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    
    --alert-item-font-family:  "Climate Crisis", sans-serif;
    --alert-item-font-size-pc:35px;
    --alert-item-font-size-tablet:35px;
    --alert-item-font-size-mobile:35px;
    --alert-item-bg-color:rgb(0, 0, 0,.5);
    --alert-item-bg-color-2:rgb(0, 0, 0,.5);
    --alert-item-font-color:rgb(66, 175, 175);
    --alert-item-border:5px solid rebeccapurple;
    --alert-item-border-2:5px solid rebeccapurple;
  
    --alert-item-bg-color-title:rgb(159, 217, 219);
    --alert-item-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;
  }








.astro-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh;
    padding: 0%;
    margin: 0%;
    gap: 5rem;
}


.astro-grid{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    min-height: 100vh;
    padding: 0%;
    margin: 0%;
    gap: 10rem;
}

.astro-button{
    margin-block: 20px;
}

.astro-moving{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border: 1px solid black;
    border-radius: 25px;
    padding-block: 60px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}

.astro-sevendays{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border: 1px solid black;
    border-radius: 25px;
    padding-block: 60px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
}

.sevenday-carousel{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    max-width: 400px;
}

.moon-carousel-image{
    border: 1px solid transparent;
    border-radius: 20px;
    display: block;
    margin: 0 auto;
    width: auto;
    height: 250px;
    outline: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    background-color: rgb(0, 0, 0,.1);
    user-select: none;
}

  
  .moon-carousel-image:focus {
    outline: none;
  }
  
  

.astro-header{
    font-family: var(--exp-font-family);
    font-weight: 100;
    font-size: 45px;
    letter-spacing: 2px;
    color: black;
    text-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
    border-radius: 100vh;
    padding: 15px;
    text-align: center;
    max-width: 600px;
    margin-block-start: 00px;
}

.astro-location{
    font-family: var(--about-font-family);
    font-size: 25px;
    font-weight: 300;
    color: black;
    padding: 20px;
    text-align: center;
    line-height: 1.6;
    word-wrap: break-word; 
    margin: 0px auto; 
    max-width: 300px;
}

.astro-image{
    border: 1px solid transparent;
    border-radius: 20px;
    display: block;
    margin: 0 auto;
    width: auto;
    height: 250px;
    outline: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    background-color: rgb(0, 0, 0,.1);
    user-select: none;
}



/*Basic Loading*/
.loading-container-moon{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading123{
    height:50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor:progress;

    border-radius: 50%;
    border-top: 5px solid rgb(0, 0, 0);
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgb(0, 0, 0);
    border-right: 5px solid transparent;

    animation: loading 1s linear infinite;
}




@keyframes loading {
  0% {
   transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*Basic Loading*/
  


.swiper-button-next,
.swiper-button-prev {
  color: rgb(161, 0, 167) !important;
  transition: color 0.3s ease;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: rgb(251, 125, 255) !important;
}



.swiper-container {
    overflow: visible !important;
  }
  

.swiper-pagination {
    bottom: -4px !important;
  }
  

.swiper-pagination-bullet {
    background-color: rgb(161, 0, 167) !important;
}
  





  
@media (max-width: 768px) {
 
    .astro-button{
        margin-block: 20px;
    }

    .astro-header{
        font-size: 35px;
        padding: 5px;
        max-width: 90%;
        margin-block-start: 50px;
    }
      
    .astro-sevendays{
        max-width: 90vw;
    }

    .astro-moving{
        max-width: 90vw;
    }

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}

.swiper-pagination {
    bottom: 5px !important;
  }
}


@media (min-width: 769px) and (max-width: 1024px) {
    .astro-button{
        margin-block: 20px;
    }

    .astro-header{
        font-size: 35px;
        padding: 5px;
        max-width: 90%;
        margin-block-start: 50px;
    }

    .astro-moving{
        min-height: auto;
    }
    
    .astro-sevendays{
        min-height: auto;
        max-width: 80vw;
    }
      
    .astro-moving{
        max-width: 80vw;
    }

.swiper-button-next,
.swiper-button-prev {
  display: none !important;
}


.swiper-pagination {
    bottom: -4px !important;
  }
}



:root{
    --headerblog-font-family: "Climate Crisis", sans-serif;
    --headerblog-font-size-pc:60px;
    --headerblog-font-size-tablet:50px;
    --headerblog-font-size-mobile:50px;
  
    --subheaderblog2-font-size-pc:30px;
    --subheaderblog2-font-size-tablet:30px;
    --subheaderblog2-font-size-mobile:30px;
    
    --blog-font-family:  "Climate Crisis", sans-serif;
    --blog-font-size-pc:20px;
    --blog-font-size-tablet:15px;
    --blog-font-size-mobile:15px;

    --blog-bg-color:rgb(0, 0, 0,.5);
    --blog-bg-color-2:rgb(0, 0, 0,.5);
    --blog-font-color:rgb(66, 175, 175);
    --blog-border:5px solid rebeccapurple;
    --blog-border-2:5px solid rebeccapurple;
  
    --blog-bg-color-title:rgb(159, 217, 219);
    --blog-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;

  }
  .unique-image-container {
    min-width: 80%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns content to the top-left */
    justify-content: flex-start; /* Ensures growth happens from top-left */
    overflow: hidden;
    position: relative;
  }
  
  .Uniqueimage {
    cursor: pointer;
    width: 60%;
    min-height: 350px;
    transform-origin: top left;
    animation: proportionalResize 30s infinite;
  }
  
  @keyframes proportionalResize {
    0%, 100% {
      width: 60%;
      height: 60%;
    }
    50% {
      width: 100%;
      height: 100%;
    }
  }
  
  
  

  @keyframes chomp {
    0%, 100% { 
      opacity: 1; 
    }
    33% { 
      opacity: 0; 
    }
    66% { 
      opacity: 0; 
    }
  }
  
  #Frame1 {
    animation: chomp 0.4s steps(1) infinite;
    animation-delay: 0s;
  }
  
  #Frame2 {
    animation: chomp 0.4s steps(1) infinite;
    animation-delay: 0.13s;
  }
  
  #Frame3 {
    animation: chomp 0.4s steps(1) infinite;
    animation-delay: 0.26s;
  }

kbd{
    padding: 3px;
    background-color: rgb(0, 0, 0,.3);
    border-radius: 5px;
}

samp{
    padding: 3px;
    background-color: rgba(0, 137, 250, 0.3);
    border-radius: 5px;
    font-weight: bold;
}

.snippet-copy-item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 15px;
    background-color: rgb(0, 0, 0,.7);
    border-radius: 3px;
    border: 1px solid black;
    color: white;
    text-align: start;
    text-wrap: wrap;
    gap: 2rem;
}

.snippet-copy{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

article{
    max-width: 80%;
}
  
.backhome55 {
  display: flex;
  align-items: center;
  font-family: var(--tooltip-font-family);
  background-color: rgb(255, 255, 255);
  color: black;
  height: 60px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid black;
  margin: 30px;
  transition: background-color 0.5s ease, transform 0.5s ease, border 0.5s ease;
  z-index: 999;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
  padding: 5px;
}


.backhome55:hover {
  transform: scale(1.01);
}

.backhome55:active {
  transform: scale(0.9);
}

.arrowhead2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: transform 0.3s ease;
}


.backhome55:hover .arrowhead2 {
  transform: translateX(-20px);
}



.arrowline12{
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000000;
  border-radius: 100vh;
  transform: rotate(45deg);
  top: 6px;
  left: -50px;
}

.arrowline22{
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000000;
  border-radius: 100vh;
  transform: rotate(-45deg);
  bottom: 6px;
  left: -50px;
}

.arrowmainline2{
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000000;
  border-radius: 100vh;
  left: -48px;
}











  .blog-main{
    background: linear-gradient(to bottom, white,white);
    z-index: 1000;
    min-height: 100vh;
  }

  /*maindiv*/
  .blog{
    border-radius: 25px;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0rem;
    padding: 00px;
    margin: 0%;
    max-width: 100vw;
    margin-bottom: 100px;
  }






/*Graph*/
.blogcard{
    width: 70vw;
    height: auto;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin: 30px;
    position: relative;
    background-color: white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    padding-bottom: 100px;
    overflow: visible;
  }

.blog-item{
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 20px auto;
    background-color: transparent;
    padding: 0px;
    gap: 2rem;
    overflow: visible;
  }

.blog-item {
    font-family: var(--header2-font-family);
    font-size: var(--blog-font-size-pc);
    color: black;
    line-height: 1.6;
    padding: 0px;
    overflow: visible;
}

.item2 p, .item5 p{
  font-family: var(--header2-font-family);
  font-size: var(--blog-font-size-pc);
  color: black;
  line-height: 1.9;
  padding: 0px;
  text-align: start;
  max-width: 600px;
}


.blog-item h1{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    font-family: var(--header2-font-family);
    font-weight: 400;
    font-size: var(--headerblog-font-size-mobile);
    max-width: 90%;
    line-height: 1.2;
}

.blog-item h2{
  font-family: var(--header2-font-family);
  font-weight: 400;
  font-size: var(--subheaderblog2-font-size-pc);
  max-width: 90%;
  line-height: 1.2;
}






.blog-appear{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}



/*1. Header*/
.item1{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 90%;
  gap: 0rem;
  overflow: visible;
}

/*2. header*/
.item4{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 90%;
  gap: 0rem;
  overflow: visible;
}


.item66{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  max-width: 90%;
  gap: 10px;
  overflow: visible;
}




.illustrator-image {
  max-width: 700px;
  object-fit:contain;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
}




.tool-container-illustrator{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  overflow: visible;
}

.pentool-image {
  width: 40px;
  object-fit:contain;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
  transform: rotate(90deg);
}

.swivel-pen {
  width: 250px;
  height: auto;
  object-fit:contain;
  overflow: visible;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;
  padding: 20px;
  background-color: rgb(72, 193, 223);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
}


.shape-tool {
  width: 250px;
  height: auto;
  object-fit:contain;
  overflow: visible;
  border-radius: 15px;
  user-select: none;
  cursor: pointer;
  padding: 20px;
  background-color: transparent;
  overflow: visible;
}


@keyframes swivelAnimation {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

#swivel-pen {
  transform-origin: center 42%;
  animation: swivelAnimation 5s infinite ease-in-out;
  overflow: visible;
}


.shape {
  opacity: 0;
  transition: opacity .1s;
}


#Rectangle {
  opacity: 1;
}

#Star {
  opacity: 0;
  animation: toggleVisibility 4s infinite;
}


@keyframes toggleVisibility {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

#Rectangle {
  animation: toggleVisibility 4s infinite;
  animation-delay: 2s;
}






.color-pallette {
  display: flex;
  flex-direction: row;
  max-width: 350px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: blanchedalmond;
  padding: 20px;
  border-radius: 15px;
  gap: 3rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
  overflow: visible;
}

.circle-1, .circle-2, .circle-3, .circle-4, .circle-5, .circle-6,
.circle-7, .circle-8, .circle-9, .circle-10, .circle-11, .circle-12 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: colortrain 3s linear infinite;
  border: 1px solid black;
}

/* Individual Circle Color Assignments */
.circle-1 { background-color: rgb(252, 77, 77); animation-delay: 0s; }
.circle-2 { background-color: rgb(252, 77, 77); animation-delay: 1s; }
.circle-3 { background-color: rgb(252, 77, 77); animation-delay: 2s; }
.circle-4 { background-color: rgb(252, 77, 77); animation-delay: 3s; }
.circle-5 { background-color: rgb(252, 77, 77); animation-delay: 4s; }
.circle-6 { background-color: rgb(252, 77, 77); animation-delay: 5s; }
.circle-7 { background-color: rgb(252, 77, 77); animation-delay: 6s; }
.circle-8 { background-color: rgb(252, 77, 77); animation-delay: 7s; }
.circle-9 { background-color: rgb(252, 77, 77); animation-delay: 8s; }
.circle-10 { background-color: rgb(252, 77, 77); animation-delay: 9s; }
.circle-11 { background-color: rgb(252, 77, 77); animation-delay: 10s; }
.circle-12 { background-color: rgb(252, 77, 77); animation-delay: 11s; }

/* Animation for Color Train Effect */
@keyframes colortrain {
  0%, 100% {
    background-color: rgb(255, 240, 105);
  }
  25% {
    background-color: rebeccapurple;
  }
  50% {
    background-color: rgb(0, 255, 136);
  }
  75% {
    background-color: rgb(252, 77, 77);
  }
}







.gradient-maker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 350px;
}

.circle1, .circle2, .circle3, .circle4, .circle5, .circle6{
  position: absolute;
  border-radius: 50%;
  border: .5px solid rgba(255, 222, 222, 0.5);
  width: 100%;
  height: 100%;
}

.circle1 {   background: radial-gradient(circle, rgba(222, 255, 251, 0.5), rgba(74, 74, 255, 0.5)); z-index: 1000; width: 15%; height: 15%;}
.circle2 {   background: radial-gradient(circle, rgba(184, 255, 225, 0.5), rgba(40, 40, 230, 0.5)); z-index: 999; width: 30%; height: 30%;}
.circle3 {   background: radial-gradient(circle, rgba(143, 255, 218, 0.5), rgba(11, 11, 173, 0.5)); z-index: 998; width: 45%; height: 45%;}
.circle4 {   background: radial-gradient(circle, rgba(94, 255, 161, 0.5), rgba(0, 0, 121, 0.5)); z-index: 997; width: 60%; height: 60%;}
.circle5 {   background: radial-gradient(circle, rgba(51, 255, 221, 0.6), rgba(0, 0, 70, 0.6)); z-index: 996; width: 75%; height: 75%;}
.circle6 {   background: radial-gradient(circle, rgba(28, 255, 187, 0.7), rgba(0, 0, 87, 0.7)); z-index: 995; width: 90%; height: 90%; background-color: #000000;   box-shadow: 0 5px 10px rgba(0, 0, 0, .5);}













/* proart-swiper.css */

.proart-swiper {
  width: 350px;
  height: 350px;
  outline: none;
  border-radius: 15px;
}

.proart-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 15px;
  overflow: hidden;
}

.proart-swiper img {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
  outline: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
}




.windy{
  width: 50px;
  height: auto;
  object-fit:contain;
  overflow: visible;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  padding: 5px;
  background: linear-gradient(to bottom, #FF7E5F, #FEB47B, #FF9A8B, #D76B7E, #8E6E95);
  box-shadow: 0 1px 10px rgba(0, 0, 0, .3);
}


.mill{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mill-wing {
  animation: rotateWings 40s linear infinite;
  transform-origin: center 48.5%;
}

@keyframes rotateWings {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.layers-christmas{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 350px;
  overflow: visible;
}

.layer-image1,
.layer-image2,
.layer-image3,
.layer-image4,
.layer-image5{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
}


.layer-image1{
  z-index: 900;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
}
.layer-image2{
  z-index: 901;
}
.layer-image3{
  z-index: 902;
}
.layer-image4{
  z-index: 903;
}
.layer-image5{
  z-index: 904;
}



/* Button Styles */
.githubbtn11 {
    display: flex;
    align-items: center;
    font-family: var(--tooltip-font-family);
    background-color: rebeccapurple;
    color: white;
    padding: 3px;
    border-radius: 05px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  .githubbtn11:hover {
    background-color: rgb(62, 26, 99);
  }
  
  .githubbtn11:active {
    transform: scale(0.9);
  }
  
  .githubimg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    filter: invert(1) brightness(100%) saturate(0%);
  }



  /* Button Styles */
.copy-btn {
    display: flex;
    align-items: center;
    font-family: var(--tooltip-font-family);
    background-color: rebeccapurple;
    color: white;
    padding: 3px;
    border-radius: 05px;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid rgb(0, 0, 0);
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease, border 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  .copy-btn:hover {
    background-color: rgb(62, 26, 99);
    border: 1px solid rgb(255, 255, 255);
  }
  
  .copy-btn:active {
    transform: scale(0.9);
  }
  
  .githubimg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
    filter: invert(1) brightness(100%) saturate(0%);
  }
  
  





































@media (max-width: 768px) {
  .Uniqueimage {
    width: 70%;
    min-height: 380px;
    animation: proportionalResize2 3s infinite ease-in-out;
  }
  
  @keyframes proportionalResize2 {
    0%, 100% {
      width: 70%;
    }
    50% {
      width: 100%;
    }
  }

  .blog{
    max-width: 100vw;
    border: none;
    gap: 5rem;
  }

 
  .blogcard{
    width: 85vw;
    height: auto;
  }

.blogcard{
    width: 85vw;
    height: auto;
    justify-content: center;
    box-shadow: none;
  }


.blogcard{
    border: 1px solid transparent;
  }

.blogcard{
    margin: 0px;
  }

.blog-item{
    gap: 1rem;
  }

.blog-item {
    font-size: var(--blog-font-size-mobile);
    line-height: 1.9;
}

.item2 p, .item5 p{
  font-size: var(--blog-font-size-mobile);
  line-height: 1.9;
}


.blog-item h1{
    font-size: var(--headerblog-font-size-mobile);
}


.item1{
  justify-content: center;
}

.item4{
  justify-content: center;
}


.item66{
  justify-content: center;
  max-width: 90%;
  gap: 20px;
}
.illustrator-image {
  border-radius: 0px;
}
.pentool-image {
  width: 20px;
  border-radius: 0px;
  overflow: visible;
}

.swivel-pen {
  max-width: 40vw;
  height: 100%;
  overflow: visible;
}




.windy{
  max-width: 40vw;
  height: 100%;
}


.shape-tool {
  max-width: 40vw;
  height: 100%;
  overflow: visible;
}


.color-pallette {
  max-width: 40vw;
  gap: 1rem;
}

.circle-1, .circle-2, .circle-3, .circle-4, .circle-5, .circle-6,
.circle-7, .circle-8, .circle-9, .circle-10, .circle-11, .circle-12 {
  width: 20px;
  height: 20px;
}

.gradient-maker {
  width:250px;
  height: 250px;
}

.layers-christmas{
  width:250px;
  height: 250px;
}

.circle1, .circle2, .circle3, .circle4, .circle5, .circle6{
  width: 100%;
  height: 100%;
}

.circle1 {   background: radial-gradient(circle, rgba(222, 255, 251, 0.5), rgba(74, 74, 255, 0.5)); z-index: 1000; width: 15%; height: 15%;}
.circle2 {   background: radial-gradient(circle, rgba(184, 255, 225, 0.5), rgba(40, 40, 230, 0.5)); z-index: 999; width: 30%; height: 30%;}
.circle3 {   background: radial-gradient(circle, rgba(143, 255, 218, 0.5), rgba(11, 11, 173, 0.5)); z-index: 998; width: 45%; height: 45%;}
.circle4 {   background: radial-gradient(circle, rgba(94, 255, 161, 0.5), rgba(0, 0, 121, 0.5)); z-index: 997; width: 60%; height: 60%;}
.circle5 {   background: radial-gradient(circle, rgba(51, 255, 221, 0.6), rgba(0, 0, 70, 0.6)); z-index: 996; width: 75%; height: 75%;}
.circle6 {   background: radial-gradient(circle, rgba(28, 255, 187, 0.7), rgba(0, 0, 87, 0.7)); z-index: 995; width: 90%; height: 90%; background-color: #000000;   box-shadow: 0 5px 10px rgba(0, 0, 0, .5);}


.proart-swiper {
  max-width: 85vw;
  height: auto;
}


.backhome55 {
  height: 40px;
  font-size: 12px;
  margin: 20px;
  margin-bottom: 0%;
}

}



  
    
@media (min-width: 769px) and (max-width: 1024px) {


  

  .blog{
    max-width: 100vw;
    border: none;
    gap: 5rem;
  }

 
  .blogcard{
    width: 85vw;
    height: auto;
  }

.blogcard{
    width: 85vw;
    height: auto;
    justify-content: center;
    box-shadow: none;
  }


.blogcard{
    border: 1px solid transparent;
  }

.blogcard{
    margin: 0px;
  }

.blog-item{
    gap: 1rem;
  }

.blog-item {
    font-size: var(--blog-font-size-mobile);
    line-height: 1.9;
}

.item2 p, .item5 p{
  font-size: var(--blog-font-size-mobile);
  line-height: 1.9;
}


.blog-item h1{
    font-size: var(--headerblog-font-size-mobile);
}



.item1{
  justify-content: center;
}

.item4{
  justify-content: center;
}


.item66{
  justify-content: center;
  max-width: 90%;
  gap: 20px;
}
.illustrator-image {
  border-radius: 0px;
}
.pentool-image {
  width: 20px;
  border-radius: 0px;
}

.swivel-pen {
  max-width: 30w;
  height: 100%;
}

.windy{
  max-width: 30vw;
  height: 100%;
}
.shape-tool {
  max-width: 30vw;
  height: 100%;
}


.color-pallette {
  max-width: 30vw;
  gap: 2rem;
}

.circle-1, .circle-2, .circle-3, .circle-4, .circle-5, .circle-6,
.circle-7, .circle-8, .circle-9, .circle-10, .circle-11, .circle-12 {
  width: 30px;
  height: 30px;
}

.gradient-maker {
  width:350px;
  height: 350px;
}

.layers-christmas{
  width:350px;
  height: 350px;
}

.circle1, .circle2, .circle3, .circle4, .circle5, .circle6{
  width: 100%;
  height: 100%;
}

.circle1 {   background: radial-gradient(circle, rgba(222, 255, 251, 0.5), rgba(74, 74, 255, 0.5)); z-index: 1000; width: 15%; height: 15%;}
.circle2 {   background: radial-gradient(circle, rgba(184, 255, 225, 0.5), rgba(40, 40, 230, 0.5)); z-index: 999; width: 30%; height: 30%;}
.circle3 {   background: radial-gradient(circle, rgba(143, 255, 218, 0.5), rgba(11, 11, 173, 0.5)); z-index: 998; width: 45%; height: 45%;}
.circle4 {   background: radial-gradient(circle, rgba(94, 255, 161, 0.5), rgba(0, 0, 121, 0.5)); z-index: 997; width: 60%; height: 60%;}
.circle5 {   background: radial-gradient(circle, rgba(51, 255, 221, 0.6), rgba(0, 0, 70, 0.6)); z-index: 996; width: 75%; height: 75%;}
.circle6 {   background: radial-gradient(circle, rgba(28, 255, 187, 0.7), rgba(0, 0, 87, 0.7)); z-index: 995; width: 90%; height: 90%; background-color: #000000;   box-shadow: 0 5px 10px rgba(0, 0, 0, .5);}


.proart-swiper {
  max-width: 85vw;
  height: auto;
}

}

:root{
  --exp-font-family: "Climate Crisis", sans-serif;

  --exptext-font-family: "Coiny", system-ui;

  --prof-name-font-family:"Lexend Deca", sans-serif;

  --header-font-family:"Honk", system-ui;


  --alert-font-family: "Rampart One", system-ui;
  --alert-font-size-pc:24px;
  --alert-font-size-tablet:20px;
  --alert-font-size-mobile:20px;

  --alert-bg-color:rgb(125, 206, 199);
  --alert-font-color:rgb(4, 0, 58);
  --alert-border:5px solid yellow;

  --form-bg-color:rgb(206, 125, 125);
  --form-font-color:white;
  --form-border:5px solid yellow;

  --footer-bg-color:rgb(0, 0, 0);
  --footer-font-color:white;

  
  --alert-btn2-color:rgb(212, 107, 107);
  --alert-btn2-hover:rgb(161, 68, 68);
  --alert-btn2-border:5px solid yellow;
  --alert-btn2-border-2:5px solid yellow;
  
  --alert-btn2-font-family: "Climate Crisis", sans-serif;
  --alert-btn2-font-size-pc:20px;
  --alert-btn2-font-size-tablet:20px;
  --alert-btn2-font-size-mobile:20px;

  --input-font-family: "Rampart One", system-ui;
  --input-font-size-pc:24px;
  --input-font-size-tablet:20px;
  --input-font-size-mobile:20px;


  --footer-font-family: "Climate Crisis", sans-serif;
  --footer-font-size-pc:40px;
  --footer-font-size-tablet:30px;
  --footer-font-size-mobile:20px;

  --form-font-family: "Poiret One", sans-serif;
  --form-font-size-pc:25px;
  --form-font-size-tablet:25px;
  --foorm-font-size-mobile:20px;


  --tip-bg-color: rgba(31, 22, 83, 0.7);     
  --tip-bg-colornav: rgb(0, 0, 0);    
  --tip-text-color: #ffffff;  
  --tip-border-color: transparent;
  --tip-font-family: "Lexend Deca", sans-serif; 
  --tip-font-size-pc: 15px;    
  --tip-font-size-tablet: 13px;  
  --tip-font-size-mobile: 12px;  
  --tip-padding: 5px;         
  --tip-border-radius: 1px;   
  --tip-max-width: 250px;      
  --tip-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

}


.upbtn {
  width: 30px;
  height: 30px;
}

.svg-path {
  fill: transparent;
  stroke: white;
  stroke-width: 2;
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  animation: drawFill 3s linear infinite;
}

@keyframes drawFill {
  0% {
    stroke-dashoffset: 1500;
    fill: transparent;
  }
  50% {
    stroke-dashoffset: 0;
    fill: rgba(255, 255, 255, 0.1);
  }
  70% {
    fill: rgba(255, 255, 255, 0.5);
  }
  100% {
    stroke-dashoffset: 0;
    fill: white;
  }
}


  .scroll-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 50vh;
    right: 1vw;
    background: transparent;
    border: 2px solid white;
    color: white;
    font-family: var(--prof-name-font-family);
    background-color: rebeccapurple;
    font-size: 25px;
    cursor: pointer;
    padding: 8px;
    border-radius: 100vh 0px 100vh 100vh;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    aspect-ratio: 1/1;
    max-width: 50px;
    max-height: 50px;
    z-index: 9999999999999;
  }
  
  .scroll-to-top:hover {
    background-color: rgb(62, 26, 99);
    border: 2px solid rgb(218, 218, 218);
    transition: 50ms;
  }
  
  .scroll-to-top:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  

  .scroll-to-top:active{
    transform:scale(0.9);
  }
  

/* Add this to your HomePage.css */
body.swal2-shown {
  padding-right: 0px !important;
}













    
/* For mobile screens */
@media (max-width: 768px) {

    /* Reset WebKit-based browsers (e.g., Chrome, Safari) */
    ::-webkit-scrollbar {
      display: none; /* Ensure the scrollbar is visible */
    }
    
    /* Reset Firefox */
    html, body, * {
      scrollbar-width: 0px; /* Restore the default width */
      -ms-overflow-style:0px; /* Restore default style for IE */
    }

    .scroll-to-top {
      display: none;
    }
      
    .scroll-to-top:hover {
      background-color: rgb(62, 26, 99);
    }
    
    .scroll-to-top:active{
      transform:scale(1);
    }
    
    }
    
    
    
    
    
    /* For tablet screens (768px to 1024px) */
    @media (min-width: 769px) and (max-width: 1024px) {
    
    /* Reset WebKit-based browsers (e.g., Chrome, Safari) */
    ::-webkit-scrollbar {
      display: none; /* Ensure the scrollbar is visible */
    }
    
    /* Reset Firefox */
    html, body, * {
      scrollbar-width: 0px; /* Restore the default width */
      -ms-overflow-style:0px; /* Restore default style for IE */
    }
    
    .scroll-to-top:hover {
      background-color: rgb(62, 26, 99);
    }
    
    .scroll-to-top:active{
      transform:scale(1);
    }
    }
    

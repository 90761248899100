:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    
    --blogs-item-font-family:  "Climate Crisis", sans-serif;
    --blogs-item-font-size-pc:25px;
    --blogs-item-font-size-tablet:25px;
    --blogs-item-font-size-mobile:25px;
    --blogs-item-bg-color:rgb(0, 0, 0,.5);
    --blogs-item-bg-color-2:rgb(0, 0, 0,.5);
    --blogs-item-font-color:rgb(66, 175, 175);
    --blogs-item-border:5px solid rgb(255, 255, 255);
    --blogs-item-border-2:5px solid rebeccapurple;
  
    --blogs-bg:linear-gradient(to bottom right, 
    rgb(230, 200, 250) 10%,   /* Soft Lavender */
    rgb(206, 147, 216) 20%,   /* Orchid */
    rgb(186, 85, 211) 30%,    /* Medium Orchid */
    rgb(148, 0, 211) 40%,     /* Dark Orchid */
    rgb(138, 43, 226) 50%,    /* Blue Violet */
    rgb(108, 0, 180) 60%,     /* Deep Purple */
    rgb(75, 0, 130) 80%,      /* Indigo */
    rgb(55, 0, 90) 90%        /* Dark Violet */
  );
}
 

.retro-mode-enable{
  
  --blogs-bg: linear-gradient(to bottom right, 
  rgb(173, 216, 230) 10%,   /* Light Sky Blue */
  rgb(135, 206, 250) 20%,   /* Deep Sky Blue */
  rgb(100, 149, 237) 30%,   /* Cornflower Blue */
  rgb(70, 130, 180) 40%,    /* Steel Blue */
  rgb(30, 144, 255) 50%,    /* Dodger Blue */
  rgb(0, 102, 204) 60%,     /* Strong Blue */
  rgb(0, 76, 153) 80%,      /* Deep Navy Blue */
  rgb(0, 51, 102) 90%       /* Dark Navy Blue */
);

}
  
.blogs-main {
     min-height: 100vh;
     background: linear-gradient(to bottom, rgba(157, 255, 137, 0.6),rgb(255, 255, 255),rgb(255, 255, 255)); }




.blogtitlediv{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  overflow-x: hidden;
}
.blog-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
.blogtitlediv {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
  }


.blog-title h1{
    text-align: center;
    font-family: var(--prof-name-font-family);
    font-size: 100px;
    font-weight: 500;
    text-shadow: 5px 5px 1px rgba(0, 0, 0, .5);
    color: rgb(29, 48, 102);
    max-width: 80vw;
    letter-spacing: 5px;
}

.blogs h2{
  text-align: center;
  font-family: var(--prof-name-font-family);
  font-size: 60px;
  font-weight: 500;
  text-shadow: 5px 5px 1px rgba(0, 0, 0, .5);
  color: rgb(29, 48, 102);
  max-width: 80vw;
  letter-spacing: 5px;
}
  
.blogs-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    padding-bottom: 200px;
    padding-top: 200px;
    background: linear-gradient(to bottom, rgb(255, 255, 255));
    z-index: 1000;
    transition: filter 0.3s ease, opacity 0.3s ease, transform 0.2s ease;
  }
  

  .blogs-items > *:hover,
  .blogs-items > *:focus {
    outline: none;
    filter: none;
    opacity: 1;
    transform: scale(1);
    transition: filter 0.3s ease, opacity 0.3s ease, transform 0.2s ease;
  }
  


  .blogs {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    width: 320px;
    height: 240px;
    border: 5px solid rgb(29, 48, 102);
    overflow: hidden;
    cursor: pointer;
    background: var(--blogs-bg);
    background-size: 200% 200%;
    animation: rainbowBorder 3s linear infinite;
    transform: scale(1);
    transition: transform 500ms linear(0, 0.011 0.8%, 0.041 1.6%, 0.158 3.3%, 0.814 9.5%, 1.04 12.1%, 1.123 13.4%, 1.185 14.7%, 1.226 16%, 1.248 17.3%, 1.252 18.9%, 1.231 20.7%, 1.19 22.6%, 1.058 27.4%, 1 29.9%, 0.958 32.6%, 0.938 35.4%, 0.941 39%, 0.999 48.1%, 1.016 53.5%, 0.996 71.3%, 1), box-shadow 200ms ease, border 200ms ease;  
  }
  
  .blogs:hover {
    border: 5px solid rgb(0, 0, 0);
    outline: none;
    filter: none;
    opacity: 1;
    transform: scale(0.95);
  }
  


  
.blogs-items a{
      color: rgb(255, 255, 255);
      z-index: 55;
      text-decoration: none;
      font-size: var(--blogs-item-font-size-pc);
      font-family: var(--exp-font-family);
}



 

.blog-disclaimer {
    margin: 1rem auto;
    font-size: 10px;
    color: #555;
    font-family: var(--header2-font-family);
    max-width: 70vw;
    text-align: center;
  }
  
  .blog-disclaimer a {
    color: #007bff;
    text-decoration: none;
  }
  
  .blog-disclaimer a:hover {
    text-decoration: underline;
}









  @media (max-width: 768px) {
    .blog-disclaimer {
        font-size: 12px;
        max-width: 80vw;
      } 

    .blog-title h1{
        text-align: center;
        font-size: 40px;
        max-width: 80vw;
        letter-spacing: 2px;
        text-shadow: 4px 2px 1px rgba(0, 0, 0, .3);
  }
    
  .blogs-items a{
    font-size: var(--blogs-item-font-size-mobile);
}
  .blogs {
      width: 320px;
      height: 250px;
  }

  .blogss{
      width: 320px;
      height: 250px;
  }
  

  
}
    
      
@media (min-width: 769px) and (max-width: 1024px) {

    .blog-disclaimer {
        font-size: 12px;
        max-width: 80vw;
      }
      .blog-title h1{
        text-align: center;
        font-size: 70px;
        max-width: 80vw;
        letter-spacing: 0px;
        text-shadow: 4px 3px 1px rgba(0, 0, 0, .3);
    }
      
    .blogs-items a{
        font-size: var(--blogs-item-font-size-tablet);
  }   
}
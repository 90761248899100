:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    --yellow:rgb(255, 245, 116);
    --red:rgb(255, 131, 131);
    --blue:rgb(161, 214, 203);
    --purple:rgb(161, 154, 211);

    --advice-font-family:  "Climate Crisis", sans-serif;
    --advice-font-size-pc:15px;
    --advice-font-size-tablet:15px;
    --advice-font-size-mobile:10px;
    --advice-bg-color:rgb(0, 0, 0,.5);
    --advice-bg-color-2:rgb(0, 0, 0,.5);
    --advice-font-color:rgb(66, 175, 175);
    --advice-border:1px solid rebeccapurple;
    --advice-border-disabled:1px solid rgb(0, 0, 0);
    --advice-border-2:5px solid rebeccapurple;
  
    --advice-bg-color-title:rgb(159, 217, 219);
    --advice-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;
  
  
  
  }

.qr-main{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}
.qr-gen-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    padding-block-end: 100px;
}

.qr-grid{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    margin-top: 25px;
}


.qr-gen-heading{
    font-family: var(--exp-font-family);
    font-weight: 100;
    font-size: 45px;
    letter-spacing: 2px;
    color: black;
    text-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    text-align: center;
    max-width: 800px;
}




.qr-gen-input {
    width:300px ;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size:var(--form-font-size-pc);
    font-family: var(--form-font-family);
    box-sizing: border-box;
    font-weight: 800;
    background-color:rgba(255, 255, 255);
    color: black;
    margin: 50px;
  }
  
  .qr-gen-input:focus {
    outline: none;
    border-color: #000000;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    background-color:rgb(255, 255, 255);
    color: black;
  }




.qr-settings{
    position: relative;
    border: 1px solid black;
    border-radius: 15px;
    margin-block: 25px;
    min-height: 200px;
    min-width: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.qr-settings-header{
    position: absolute;
    top: -40px;
    left: 20px;
    font-family: var(--header2-font-family);
    font-weight: 400;
    font-size: 15px;
    color: black;
    text-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
}

.qr-gen-color-picker{
    padding: 10px;
    font-size:20px;
    font-family: var(--header2-font-family);
    box-sizing: border-box;
    font-weight: 300;
    cursor: cell;
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: center;
}


  
  .qr-gen-color-picker label {
    margin-left: 20px;
    user-select: none;
  }
  
  .qr-gen-color-picker input[type="color"] {
    border: 2px solid rgb(0, 0, 0, .5);
    width: 40px;
    height: 40px;
    padding: 0px;
    background: none;
    cursor: cell;
    outline: none;
    border-radius: 5px;
  }
  
  .qr-gen-color-picker input[type="color"]:hover {
    border: 2px solid black;
  }









  .qr-gen-output {
    position: relative;
    border: 1px solid black;
    border-radius: 15px;
    margin-block: 25px;
    max-width: auto;
    min-height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .qr-button-grid{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .please-enter-url{
    padding: 8px;
    font-size: 20px;
    font-family: var(--header2-font-family);
    font-weight: 300;
    text-align: center;
  }
  
  .qr-gen-output select {
    margin: 15px;
    padding: 8px;
    font-size: 1rem;
    font-family: var(--header2-font-family);
    border: 1px solid black;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    transition: border-color 0.3s ease;
    font-weight: 500;
  }
  
  .qr-gen-output select:focus {
    border-color: var(--primary-color);
    outline: none;
  }
  




  .qr-gen-output button {
    background: transparent;
    border: var(--advice-border);
    color: white;
    font-family: var(--prof-name-font-family);
    background-color: rebeccapurple;
    font-size: 16px;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-top: 15px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    margin: 25px;
}
  
  .qr-gen-output button:hover {
    background-color: rgb(62, 26, 99);
    transition: 150ms;
  }
  
  .qr-gen-output button:disabled {
    background-color: #686868;
    cursor: not-allowed;
    border: var(--advice-border-disabled);
  }

  .qr-gen-output button:active {
    transform: scale(0.9);
  }
  


  .qr-code{
    margin: 25px;
    max-height: 120px;
    border: 1px solid black;
    width: 120px;
    padding: 10px;
    border-radius: 15px;
  }
  
  .qr-gen-output .qr-gen-qr img {
    max-width: 100%;
    height: 150px;
  }
  
  















  .custom-toast-container2 .Toastify__toast {
    font-size: var(--advice-font-size-pc);
    font-family: var(--prof-name-font-family);
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 80vw;
    margin: 10px;
    overflow: hidden;
  }

  .custom-toast-container2 .Toastify__toast.custom-toast-warning {
    text-align: start;
    word-wrap: break-word;
  }
  

  
  /* Warning Toast Styling */
.custom-toast-container2 .Toastify__toast--warning {
    background-color: #ff9800;
    color: white;
  }




  /* Custom Close Button Styling */
.custom-toast-container2 .Toastify__toast .Toastify__close-button {
    font-size: 15px;
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .custom-toast-container2 .Toastify__toast .Toastify__close-button:hover {
    color: #000000;
  }
  
  .custom-toast-container2 .Toastify__toast .Toastify__close-button:focus {
    outline: none;
  }
  



















  
  
  @media (max-width: 768px) {

    .custom-toast-container2 .Toastify__toast {
        font-size: 13px;
        padding: 20px;
        max-width: 60vw;
      }

      .qr-gen-heading{
        font-size: 35px;
        padding: 5px;
        max-width: 90%;
        margin-block-start: 50px;
    }


.qr-gen-heading{
    max-width: 80vw;
}

.qr-gen-input {
    width:80vw ;
    max-width: 100vw;
  }
  
.qr-settings{
    width: 80vw;
    max-width: 80vw;
}

.qr-gen-color-picker{
    font-size:20px;
}


.qr-gen-output {
    width: 80vw;
    max-width: 80vw;
  }

    
}


@media (min-width: 769px) and (max-width: 1024px) {
.custom-toast-container2 .Toastify__toast {
    font-size: 15px;
    padding: 25px;
    max-width: 60vw;
  }

  .qr-gen-heading{
    font-size: 35px;
    padding: 5px;
    max-width: 90%;
    margin-block-start: 50px;
}


}
:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    
    --gallery-item-font-family:  "Climate Crisis", sans-serif;
    --gallery-item-font-size-pc:35px;
    --gallery-item-font-size-tablet:35px;
    --gallery-item-font-size-mobile:35px;
    --gallery-item-bg-color:rgb(0, 0, 0,.5);
    --gallery-item-bg-color-2:rgb(0, 0, 0,.5);
    --gallery-item-font-color:rgb(66, 175, 175);
    --gallery-item-border:5px solid rebeccapurple;
    --gallery-item-border-2:5px solid rebeccapurple;
  
    --gallery-item-bg-color-title:rgb(159, 217, 219);
    --gallery-item-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;
}

/* Toast Styles */
.custom-toast {
  font-size: var(--advice-font-size-pc);
  font-family: var(--prof-name-font-family);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 80vw;
  margin: 10px;
  overflow: hidden;
}

.custom-toast-loading{
  background-color: rgb(255, 227, 149) !important;
  color: rgb(0, 0, 0) !important;
  font-weight: bold !important; 
}
.custom-toast-success {
  background-color: rebeccapurple !important;
  color: white !important;
  font-weight: bold !important;
}

.custom-toast-error {
  background-color: #f35757 !important;
  color: white !important;
  font-weight: bold !important;
}

/* Customize the toast container position */
.Toastify__toast-container--bottom-right {
  bottom: 20px;
  right: 20px;
}

/* Add a subtle animation for toast */
.Toastify__toast {
  transition: transform 0.3s ease;
}

.Toastify__toast:hover {
  transform: translateY(-3px);
}



.Wall-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.photo-gallery {
  display: flex;
  justify-content: center;
  max-width: 100vw;

}

.gallery-column {
  padding: 10px;
}

  





  .loading-unsplash{
    position: absolute;
    height:30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor:progress;

    border-radius: 50%;
    border-top: 5px solid rgb(0, 0, 0);
    border-bottom: 5px solid transparent;
    border-left: 5px solid rgb(0, 0, 0);
    border-right: 5px solid transparent;

    animation: loading2point0 1s linear infinite;
}

.titlediv2{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(151, 137, 255, 0.6),rgb(255, 255, 255),rgb(255, 255, 255));
  
  }


.Wally-title{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  
.Wally-title2{
      text-align: center;
      font-family: var(--prof-name-font-family);
      font-size: 100px;
      font-weight: 500;
      text-shadow: 5px 5px 1px rgba(0, 0, 0, .5);
      color: rebeccapurple;
      max-width: 80vw;
      letter-spacing: 5px;
  }

  .poweredbyunsplash {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    user-select: none;
    z-index: 100;
  }
  
  .unsplashlogomain {
    padding: 1px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
  }
  
  
  .poweredbyunsplash p {
    position: relative;
    font-family: var(--exptext2-font-family);
    font-size:10px;
    font-weight: 500;
    color: rgb(0, 0, 0);
    text-align: start;
    line-height: 1.6;
    word-wrap: break-word; 
    cursor: pointer;
  }





@keyframes loading2point0 {
  0% {
   transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
  


.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 10px;
}


.author-name,
.download-icon {
  position: absolute;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  display: none;
  align-items: center;
  font-family: var(--header2-font-family);
  font-size: 12px;
  text-align: center;
}

.author-name{
  left: 10px;
  bottom: 10px;
  right: auto;
  cursor: pointer;
}
.download-icon {
  right: 10px;
  top: 10px;
  left: auto;
  cursor: pointer;
}

.image-container:hover .author-name,
.image-container:hover .download-icon {
  display: flex;
}

.image-container:active .download-icon{
  transform: scale(1.1);
}


.image-container img {
  width: 100%;
  height: auto;
}

.gallery-item .lazy-load-image-background {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px;
}

.gallery-item .lazy-load-image-background img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
  transition: transform 0.5s ease;
}

.gallery-item:hover .lazy-load-image-background img {
  transform: scale(1.1);
}



.unsplash-disclaimer {
  margin: 1rem auto;
  font-size: 10px;
  color: #555;
  font-family: var(--header2-font-family);
  max-width: 70vw;
  text-align: center;
}

.unsplash-disclaimer a {
  color: #007bff;
  text-decoration: none;
}

.unsplash-disclaimer a:hover {
  text-decoration: underline;
}

.image-items-unsplash{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.download-icon:hover {
  background-color: rgba(0, 0, 0, 0.7);
}








@media (max-width: 768px) {
    
.Wally-title2{
      text-align: center;
      font-size: 40px;
      max-width: 80vw;
      letter-spacing: 2px;
      text-shadow: 4px 2px 1px rgba(0, 0, 0, .3);
  }


  .unsplash-disclaimer {
    font-size: 12px;
    max-width: 80vw;
  }

.author-name,
.download-icon {
  display: flex;
  font-size: 12px;
}

.download-icon svg {
  width: 15px;
  height: 15px;
}

}


@media (min-width: 769px) and (max-width: 1024px) {
.Wally-title2{
      text-align: center;
      font-size: 70px;
      max-width: 80vw;
      letter-spacing: 0px;
      text-shadow: 4px 3px 1px rgba(0, 0, 0, .3);
  }

  .unsplash-disclaimer {
    font-size: 12px;
    max-width: 80vw;
  }

  .author-name,
.download-icon {
  display: flex;
  font-size: 12px;
}

.download-icon svg {
  width: 18px;
  height: 18px;
}
}
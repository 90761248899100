:root{
  --nav-bg-color:rgb(0, 0, 0,.8);
  --nav-shrink-color:rgb(0, 0, 0, .5);
  --nav-items-padding:7px;
  --navbar-border:none;

  --navlogo-font: "Combo", system-ui;
  --navlogo-color:white;
  --navlogo-color-hover:rebeccapurple;
  --navlogo-font-size:30px;
  --navlogo-bg-color:transparent;
  --navlogo-bg-color-hover:transparent;
  --navlogo-border:none;
  --navlogo-border-hover:none;

  --nav-li-font-family:  "Lexend Deca", sans-serif; 
  --nav-li-font-size:18px;
  --nav-li-font-color:rgb(255, 255, 255);
  --nav-li-hover-color:rgb(117, 117, 117);
  --nav-li-bg-color:transparent;
  --nav-li-border:3px solid transparent;

  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);
}


.retro-mode-enable{
  --nav-bg-color:rgb(0, 0, 0,.8);
  --nav-shrink-color:rgb(0, 0, 0, .5);
  --nav-items-padding:7px;
  --navbar-border:none;

  --navlogo-font: "Combo", system-ui;
  --navlogo-color:white;
  --navlogo-color-hover:rgb(78, 102, 209);
  --navlogo-font-size:30px;
  --navlogo-bg-color:transparent;
  --navlogo-bg-color-hover:transparent;
  --navlogo-border:none;
  --navlogo-border-hover:none;

  --nav-li-font-family:  "Lexend Deca", sans-serif; 
  --nav-li-font-size:18px;
  --nav-li-font-color:rgb(255, 255, 255);
  --nav-li-hover-color:rgb(117, 117, 117);
  --nav-li-bg-color:transparent;
  --nav-li-border:3px solid transparent;

  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);
}





.nav-main {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  margin: 0%;
}

.navbar {
  position: fixed;
  top: 25px;
  background: var(--nav-bg-color);
  color: #fff;
  padding: 0 30px;
  z-index: 1000;
  height: 80px;
  width: 45vw;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-radius: 100vh;
}




.navbar.shrink {
  width: 65vw;
  background:   
  radial-gradient(circle at top left, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle at top right, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom left, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom right, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle, rgb(102, 51, 153,.7), transparent 50%);
  backdrop-filter: blur(8px); 
}

.navbar.hidden {
  top: -80px; 
  opacity: 0; 
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.logo {
  font-size: var(--navlogo-font-size);
  font-weight: 100;
  color: var(--navlogo-color);
  cursor: pointer;
  font-family: var(--navlogo-font);
  background-color: transparent;
  padding: var(--nav-items-padding);
  border: none;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out;
}

.logo:hover {
  color: var(--navlogo-color-hover);
  background-color: var(--navlogo-bg-color-hover);
  border: var(--navlogo-border-hover);
}


.menu-icon {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  text-decoration: none;
}



.navbar-items {
  display: flex;
  list-style: none;
  gap: 20px;
  cursor: pointer;
  font-family: var(--nav-li-font-family);
  font-weight: 400;
  font-style: normal;
  font-size: var(--nav-li-font-size);
  color: var(--nav-li-font-color);
  transition: transform 0.3s ease-in-out;
  text-decoration: none;
}
.navbar-items a {
  text-decoration: none;
  color: inherit;
}
.navbar-items {
  display: flex;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

.navbar-items li {
  position: relative;
  list-style: none;
  padding: var(--nav-items-padding);
  background-color: transparent;
  border: 3px solid transparent;
  transition: color 0.3s, background-color 0.3s;
  z-index: 10000;
  overflow: hidden;
  text-decoration: none;
}

.navbar-items li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--nav-li-bg-color); 
  transform: scaleX(0); 
  transform-origin:left; 
  transition: transform 0.1s ease-out;
  z-index: -1; 
  text-decoration: none;
}

.navbar-items li:hover::before {
  transform: scaleX(1);
}

.navbar-items li:hover {
  border: var(--nav-li-border); 
  background-color: transparent; 
 color: var(--navlogo-color-hover);
 transition: color 200ms ease-in-out;
 text-decoration: none;
}

.navbar-items li:not(:hover)::before {
  transform: scaleX(0); 
}


.navbar-items.active {
  display: flex;
  flex-direction: column;
  background: #000000;
  position: fixed;
  top: 60px;
  right: 20px;
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
  text-decoration: none;
}


.logo, .menu-icon {
  visibility: visible; /* Ensure they remain visible */
  opacity: 1;          /* Prevent accidental fade-outs */
  transition: opacity 0.3s ease;
}


.navbar.hidden {
  display: none;
}










/* Responsive Navbar */
/* For mobile screens */
@media (max-width: 768px) {
  .navbar.shrink .logo,
  .navbar.shrink .menu-icon {
    visibility: visible;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .logo{
    font-size: 25px;
  }

  .logo:hover {
    color: var(--navlogo-color);
    background-color: var(--navlogo-bg-color);
    border: var(--navlogo-border);
  }

  .navbar-items li:hover {
   color: var(--nav-li-font-color);
  }

  .navbar{
    width:60vw;
    height: 55px;
  }
  .navbar.shrink {
    width: 65vw;
    background: var(--nav-shrink-color); 
    backdrop-filter: blur(8px); 
  }

  .menu-icon {
    height: 30px;
    width: 30px;
    display: block;
    color: var(--purple);
    padding: var(--nav-items-padding);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .navbar-items {
    display: none; /* Hide navbar items by default on mobile */
  }

  .navbar-items.active {
    margin-top: 30px;
    display: flex;
    flex-direction: column; /* Ensure menu items stack vertically */
    background-color: rgb(0, 0, 0,.9);
    border: var(--navbar-border);
  }

  .navbar.shrink {
    width: 70vw;
  }

  /* Hide navbar when it's in the 'hidden' state */
  .navbar.hidden {
    display: none;
  }
}


/* For tablet screens (768px to 1024px) */
@media (min-width: 769px) and (max-width: 1440px) {
  .navbar.shrink .logo, .navbar.shrink .menu-icon {
    visibility: visible; /* Explicitly keep them visible */
  }
  .navbar{
    border-block-end: var(--navbar-border);
    width:60vw;
    height: 65px;
  }

  ::-webkit-scrollbar {
    display: none;  /* Hide the scrollbar */
  }
  .logo:hover {
    color: var(--navlogo-color);
    background-color: var(--navlogo-bg-color);
    border: var(--navlogo-border);
  }
  .navbar-items li:hover {
    color: var(--nav-li-font-color);
   }
  .menu-icon {
    height: 35px;
    width: 35px;
    display: block;
    color: var(--purple);
    padding: var(--nav-items-padding);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .navbar-items {
    display: none;
  }

  .navbar-items.active {
    margin-top: 30px;
    display: flex;
    flex-direction: column; /* Stack vertically for tablets as well */
    background-color: #000000;
    border: var(--navbar-border);
  }

  .navbar.shrink {
    padding: 0px 80px;
    width: 70vw;
  }
  .navbar.hidden {
    display: none;
  }
}

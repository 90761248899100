:root{
  --exp-font-family: "Climate Crisis", sans-serif;
  --exp-font-size-pc:50px;
  --exp-font-size-tablet:40px;
  --exp-font-size-mobile:40px;

  
  --fun-item-font-family:  "Climate Crisis", sans-serif;
  --fun-item-font-size-pc:25px;
  --fun-item-font-size-tablet:25px;
  --fun-item-font-size-mobile:25px;
  --fun-item-bg-color:rgb(0, 0, 0,.5);
  --fun-item-bg-color-2:rgb(0, 0, 0,.5);
  --fun-item-font-color:rgb(66, 175, 175);
  --fun-item-border:5px solid rgb(255, 255, 255);
  --fun-item-border-2:5px solid rebeccapurple;

  --fun-bg:linear-gradient(to bottom right, 
  rgb(230, 200, 250) 10%,   /* Soft Lavender */
  rgb(206, 147, 216) 20%,   /* Orchid */
  rgb(186, 85, 211) 30%,    /* Medium Orchid */
  rgb(148, 0, 211) 40%,     /* Dark Orchid */
  rgb(138, 43, 226) 50%,    /* Blue Violet */
  rgb(108, 0, 180) 60%,     /* Deep Purple */
  rgb(75, 0, 130) 80%,      /* Indigo */
  rgb(55, 0, 90) 90%        /* Dark Violet */
);


  --fun-item-bg-color-title:rgb(159, 217, 219);
  --fun-item-border-title:5px solid rgb(0, 0, 0);

  --prof-name-font-family:"Lexend Deca", sans-serif;

  
  --header2-font-family:"Lexend Deca", sans-serif;

  --fun-main-bg:linear-gradient(to bottom, rgb(39, 30, 110),rgb(0, 0, 0),rgb(39, 30, 110));
  

}


.retro-mode-enable{
  --fun-item-bg-color:rgb(0, 0, 0,.5);
  --fun-item-bg-color-2:rgb(0, 0, 0,.5);
  --fun-item-font-color:rgb(66, 175, 175);
  --fun-item-border:5px solid rgb(0, 0, 0);
  --fun-item-border-2:5px solid rebeccapurple;

  --fun-main-bg:linear-gradient(to bottom, rgb(165, 228, 247),rgb(188, 179, 255));
  
  --fun-bg: linear-gradient(to bottom right, 
  rgb(173, 216, 230) 10%,   /* Light Sky Blue */
  rgb(135, 206, 250) 20%,   /* Deep Sky Blue */
  rgb(100, 149, 237) 30%,   /* Cornflower Blue */
  rgb(70, 130, 180) 40%,    /* Steel Blue */
  rgb(30, 144, 255) 50%,    /* Dodger Blue */
  rgb(0, 102, 204) 60%,     /* Strong Blue */
  rgb(0, 76, 153) 80%,      /* Deep Navy Blue */
  rgb(0, 51, 102) 90%       /* Dark Navy Blue */
);

}







.fun-component{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.fun-main{
  background: var(--fun-main-bg);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block-end: 300px;
}




.fun-items{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  font-family: var(--fun-item-font-family);
  font-size: var(--fun-item-font-size-pc);
  padding-block-start: 100px;
}
.item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  width: 300px;
  height: 300px;
  cursor: pointer;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
  margin: 15px;
  border: 5px solid transparent;
  background: var(--fun-bg);
  background-size: 200% 200%;
  animation: rainbowBorder 3s linear infinite;
  z-index: 10;
  transform: scale(1);
  transition: transform 500ms linear(0, 0.011 0.8%, 0.041 1.6%, 0.158 3.3%, 0.814 9.5%, 1.04 12.1%, 1.123 13.4%, 1.185 14.7%, 1.226 16%, 1.248 17.3%, 1.252 18.9%, 1.231 20.7%, 1.19 22.6%, 1.058 27.4%, 1 29.9%, 0.958 32.6%, 0.938 35.4%, 0.941 39%, 0.999 48.1%, 1.016 53.5%, 0.996 71.3%, 1), box-shadow 200ms ease, border 200ms ease;
}


.item::after {
  content: '';
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 20px;
  background-color: rgba(0, 7, 66, 0.5);
  z-index: 15;
}

.item:hover {
  border: var(--fun-item-border);
  outline: none;
}

  .fun-items:has(.item:hover) > :not(:hover) {
    filter: blur(2px);
    opacity: 0.8;
  }
  
  .fun-items > *:hover,
  .fun-items > *:focus {
    outline: none;
    filter: none;
    opacity: 1;
    transform: scale(0.95);
  }
  



  @keyframes rainbowBorder {
    0% {
      background-position: 10% 10%; /* Start the animation from the top-left corner */
    }
    14.28% {
      background-position: 20% 20%; /* Move the background slightly */
    }
    28.57% {
      background-position: 50% 50%; /* Move the background slightly */
    }
    42.85% {
      background-position: 60% 60%; /* Move the background slightly */
    }
    57.14% {
      background-position: 50% 50%; /* Move the background slightly */
    }
    71.42% {
      background-position: 40% 40%; /* Move the background slightly */
    }
    85.71% {
      background-position: 20% 20%; /* Move the background slightly */
    }
    100% {
      background-position: 10% 10%; /* Move the background to the bottom-right corner */
    }
  }


  .fun-items p{
    text-align: center;
    color: white;
    z-index: 55;
    margin: 0%;
  }




  @media (max-width: 768px) {
    .fun-items:has(.item:hover) > :not(:hover) {
      filter: none;
      opacity: 1;
    }

    .item:hover{
      border: 5px solid transparent; 
      transform: scale(1);
    }

.item {
    width: 80vw;
    height: 100px;
  }

  .fun-items p{
    font-size: var(--fun-item-font-size-mobile);
  }
  }

  
@media (min-width: 769px) and (max-width: 1024px) {
  .fun-items:has(.item:hover) > :not(:hover) {
    filter: none;
    opacity: 1;
  }

  .item:hover{
    border: 5px solid transparent; 
    transform: scale(1);
  }
  
  .item {
    width: 60vw;
    height: 150px;
  }

  .fun-items p{
    font-size: var(--fun-item-font-size-tablet);
  }
  
}
:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    --yellow:rgb(255, 245, 116);
    --red:rgb(255, 131, 131);
    --blue:rgb(161, 214, 203);
    --purple:rgb(161, 154, 211);

    --wordgen-font-family:  "Climate Crisis", sans-serif;
    --wordgen-font-size-pc:15px;
    --wordgen-font-size-tablet:15px;
    --wordgen-font-size-mobile:10px;
    --wordgen-bg-color:rgb(0, 0, 0,.5);
    --wordgen-bg-color-2:rgb(0, 0, 0,.5);
    --wordgen-font-color:rgb(66, 175, 175);
    --wordgen-border:1px solid rebeccapurple;
    --wordgen-border-2:5px solid rebeccapurple;
  
    --wordgen-bg-color-title:rgb(159, 217, 219);
    --wordgen-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;
  
  
  
  }

  .backhome3 {
    display: flex;
    align-items: center;
    font-family: var(--tooltip-font-family);
    background-color: rgb(255, 255, 255);
    color: black;
    height: 60px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid black;
    margin: 20px;
    transition: background-color 0.5s ease, transform 0.5s ease, border 0.5s ease;
    z-index: 999;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
    padding: 5px;
  }
  
  
  .backhome3:hover {
    transform: scale(1.01);
  }
  
  .backhome3:active {
    transform: scale(0.9);
  }
  
  .arrowhead2 {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    transition: transform 0.3s ease;
  }
  
  
  .backhome3:hover .arrowhead2 {
    transform: translateX(-20px);
  }
  
  
  
  .arrowline12{
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #000000;
    border-radius: 100vh;
    transform: rotate(45deg);
    top: 6px;
    left: -50px;
  }
  
  .arrowline22{
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #000000;
    border-radius: 100vh;
    transform: rotate(-45deg);
    bottom: 6px;
    left: -50px;
  }
  
  .arrowmainline2{
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #000000;
    border-radius: 100vh;
    left: -48px;
  }
  
  
  
  .wordgen-main{
    background: linear-gradient(to bottom, white,white);
  }

  .wordgen{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0%;
    padding: 0%;
    padding-block-end: 100px;
  }

.wordgen-grid{
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 440px;
    height: auto;
    border: var(--wordgen-border);
    border-radius: 25px;
    padding: 25px;
    background-color: white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}


.wordgen-window{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wizzard {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    font-size: 50px;
    background-color: var(--purple);
    border: var(--wordgen-border);
    pointer-events: none;
    transition: transform 0.5s ease;
  }
  

  @keyframes wizardShake {
    0% {
        background-color: var(--red);
      }
      25% {
        background-color: var(--yellow);
      }
      50% {
        background-color: var(--blue);
      }
      75% {
        background-color: var(--yellow);
      }
      100% {
        background-color: var(--red);
      }
    }
  
  .wizzard.wizzanimating {
    animation: wizardShake .5s ease-in-out infinite;
    border-radius: 50%;
  }
  
  


.wordgen-text{
    width: auto;
    position: relative;
    font-family: var(--exptext2-font-family);
    font-size: var(--exptext2-font-size-pc);
    font-weight: 300;
    color: rebeccapurple;
    background-color: rgb(102, 51, 153,.3);
    padding: 20px;
    text-align: center;
    line-height: 1.6;
    word-wrap: break-word; 
    margin: 20px auto; 
    max-width: 80%;
    max-height: 500px;
    border: var(--wordgen-border);
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.1s ease;
    cursor: pointer;
}

.ortext{
  width: auto;
  position: relative;
  font-family: var(--exptext2-font-family);
  font-size: var(--exptext2-font-size-pc);
  font-weight: 600;
  color: rebeccapurple;
  padding: 5px;
  border-radius: 100vh;
  text-align: center;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  background-color: rgb(0, 0, 0,.1);
  overflow: hidden;
  transition: all 0.1s ease;
  pointer-events: none;
}
.totalwordgen{
    width: auto;
    position: relative;
    font-family: var(--exptext2-font-family);
    font-size: 13px;
    font-weight: 300;
    color: rebeccapurple;
    padding: 10px;
    text-align: center;
    line-height: 1.6;
    word-wrap: break-word; 
    margin: 20px auto; 
    max-width: 80%;
}

.click-count {
    font-size: 20px;
    color: rebeccapurple;
    font-weight: bold;
  }
  
  

.poweredwordgen{
    position: relative;
    font-family: var(--exptext2-font-family);
    font-size:10px;
    font-weight: 300;
    color: black;
    text-align: start;
    line-height: 1.6;
    word-wrap: break-word; 
    margin-top: 40px;
}

.poweredwordgen a{
    cursor: pointer;
}

.wordgen-text.wordgenanimating {
    animation: textanimateonthink .5s ease-in-out infinite;
  }

  @keyframes textanimateonthink {
    0% {
        background-color: white;
      }
      25% {
        background-color: black;
        color: white;
      }
      50% {
        background-color: var(--blue);
      }
      75% {
        background-color: black;
        color: white;
      }
      100% {
        background-color: white;
      }
    }

.wordgen-button{
    background: transparent;
    border: var(--wordgen-border);
    color: white;
    font-family: var(--prof-name-font-family);
    background-color: rebeccapurple;
    font-size: 16px;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.wordgen-button:hover {
    background-color: rgb(62, 26, 99);
    transition: 150ms;
  }
  
.wordgen-button:active {
    transform: scale(0.9);
  }




.custom-toast-container .Toastify__toast {
    font-size: var(--wordgen-font-size-pc);
    font-family: var(--prof-name-font-family);
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 80vw;
    margin: 10px;
    overflow: hidden;
  }

  .custom-toast-container .Toastify__toast.custom-toast-success,
  .custom-toast-container .Toastify__toast.custom-toast-warning {
    text-align: start;
    word-wrap: break-word;
  }
  
  /* Success Toast Styling */
  .custom-toast-container .Toastify__toast.custom-toast-success {
    background-color: rebeccapurple;
    color: white;
    font-weight: bold;
  }
  
  /* Warning Toast Styling */
  .custom-toast-container .Toastify__toast.custom-toast-warning {
    background-color: #f35757;
    color: white;
    font-weight: bold;
  }

  /* Progress Bar Styling */
  .custom-toast-container .Toastify__toast.custom-toast-warning .Toastify__progress-bar {
    height: 10px;
    border-radius: 100vh;
    background-color: rgb(0, 0, 0);
  }

  .custom-toast-container .Toastify__toast.custom-toast-success .Toastify__progress-bar {
    height: 10px;
    border-radius: 100vh;
    background-color: rgb(72, 255, 0);
  }


/* Default Close Button Styling */
.Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 15px;
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 0;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .Toastify__close-button:hover {
    color: #000000;
  }
  
  .Toastify__close-button:focus {
    outline: none;
  }
  

  .wordgen-input input {
    max-width: 280px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1.5px solid rebeccapurple;
    outline: none;
    font-family: var(--header2-font-family);
    transition: border-color 0.3s ease;
  }
  
  .wordgen-input input:focus {
    border-color: rgb(0, 0, 0);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  .wordgen-input input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .wordgen-input input::placeholder {
    color: #aaa;
    font-style: italic;
  }
  

.custom-gen{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}








  

  
  
  
  
 
  
    @media (max-width: 768px) {

        .custom-toast-container .Toastify__toast {
            font-size: 13px;
            padding: 20px;
            max-width: 60vw;
          }

          .Toastify__close-button {
            position: absolute;
            top: 30%;
            right: 10px;
          }

          .wordgen-button{
            border: 3px solid white;
            font-size: 15px;
            padding: 15px;
            border-radius: 100vh;
            margin-top: 15px;
        }

          .wordgen-grid{
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 90vw;
            height: auto;
            border-radius: 25px;
            background-color: rgb(255, 255, 255,.5);
            padding: 0;
            padding-block: 40px;
        }

        .wordgen-text{
            background-color: rgba(255, 255, 255, 0.3);
            border: var(--wordgen-border);
            border-radius: 20px;
        }

        .backhome3 {
            height: 40px;
            font-size: 12px;
            margin: 20px;
          }
        
    }
  
    
  @media (min-width: 769px) and (max-width: 1024px) {
    .custom-toast-container .Toastify__toast {
        font-size: 15px;
        padding: 25px;
        max-width: 60vw;
      }

      .Toastify__close-button {
        position: absolute;
        top: 30%;
        right: 10px;
      }

      .wordgen-button{
        border: 3px solid white;
        font-size: 15px;
        padding: 15px;
        border-radius: 100vh;
        margin-top: 15px;
    }



  }
  .custom-image-size {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }
  
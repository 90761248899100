
:root{

  --ntfound-font-family:"Lexend Deca", sans-serif; 
  --ntfound-font-size-pc:18px;
  --ntfound-font-size-tablet:18px;
  --ntfound-font-size-mobile:18px;
  --ntfound-bg-color:rgb(0, 0, 0,.5);
  --ntfound-bg-color-2:rgb(0, 0, 0,.5);
  --ntfound-font-color:rgb(66, 175, 175);
  --ntfound-border:5px solid rebeccapurple;
  --ntfound-border-2:5px solid rebeccapurple;

  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203, .7);
  --purple:rgb(161, 154, 211);

}


.notfound-main{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  
  /* Gradient Background */
  background: linear-gradient(
    to bottom right, 
    rgb(240, 68, 68) 10%, 
    rgb(255, 123, 0) 20%, 
    rgb(236, 236, 122) 30%, 
    rgb(55, 139, 97) 40%, 
    rgb(62, 62, 148) 50%, 
    rgb(175, 78, 245) 60%, 
    rgb(255, 198, 255) 80%, 
    rgb(255, 85, 85) 90%
  );

  /* Make Background Fixed */
  background-attachment: fixed;
  
  /* Animation */
  background-size: 800% 800%;
  animation: rainbowBorder 10s linear infinite;
}



.notfoundtext {
  position: relative;
  font-family: var(--ntfound-font-family);
  font-size:10px;
  font-weight: 100;
  color: white;
  background-color: rgb(0, 0, 0,.5);
  padding: 20px;
  text-align: center;
  line-height: 1.6;
  word-wrap: break-word; 
  margin: 20px auto; 
  max-width: 60vw;
  border: 5px solid rebeccapurple;
  z-index: 98;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
} 

.sparkle2 {
  position: absolute;
  top: -52px;
  right: -41.5px;
  height: 100px;
  width: 80px;
  background: var(--yellow);
  mask: radial-gradient(#0000 71%, #000 72%) 10000% 10000%/99.5% 99.5%;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  animation: sparkleEffect 5s infinite ease-in-out;
  z-index: 99;
}

.sparkle2::after {
  content: '';
  --b: 2px; 
  position: absolute; 
  top: 0; 
  left: 0; 
  height: 100px;
  width: 80px;
  background: var(--red);
  mask: radial-gradient(#0000 71%, #000 72% calc(72% + var(--b)), #0000 calc(73% + var(--b))) 10000% 10000%/99.5% 99.5%;
  z-index: 100;
}





.unhappy {
  user-select: none;
    position: relative;
    height: 90px;
    width: 90px;
    border: 5px solid var(--yellow);
    border-radius: 50%;
    background: radial-gradient(
      circle at top right, /* Light source at the top-right corner */
      rgba(255, 255, 255, 0.6), /* Slightly softer light color */
      rgba(255, 237, 155, 0.8) 40%, /* Transition to a more refined color */
      yellow 80% /* Darker red for the deeper shadow effect */
    );
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3), 
                inset 3px 3px 12px rgba(255, 255, 255, 0.3); /* Softer shadows */

                animation: sparkleEffect 5s infinite ease-in-out;
  }
  
  



.cross11{
    color: var(--red);
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 35px;
    transform: rotate(-30deg);
}

.cross22{
    color: var(--red);
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 35px;
    transform: rotate(30deg);
}

.frown{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    border-top: 8px solid red;
    border-bottom: 8px solid transparent;
    border-left: 8px solid red;
    border-right: 8px solid transparent;
    top: 60px;
    left: 22px;
    transform: rotate(45deg);
}




@keyframes sparkleEffect {
    0% {
      transform: scale(1) rotate(0deg);
    }
    20% {
      transform: scale(1.1) rotate(10deg);
    }
    40% {
      transform: scale(1.15) rotate(-5deg);
    }
    60% {
      transform: scale(1.1) rotate(5deg);
    }
    80% {
      transform: scale(1.12) rotate(-5deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }
  




  .redirect-button {
    background: transparent;
    border: 1px solid black;
    color: white;
    font-family: var(--prof-name-font-family);
    background-color: rebeccapurple;
    font-size: 16px;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-top: 15px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  }
  
  .redirect-button:hover {
    background-color: rgb(62, 26, 99);
    transition: 150ms;
  }
  
  .redirect-button:active {
    transform: scale(0.9);
  }






















/* For mobile screens */
@media (max-width: 768px) {

/* Reset WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Ensure the scrollbar is visible */
}

/* Reset Firefox */
html, body, * {
  scrollbar-width: 0px; /* Restore the default width */
  -ms-overflow-style:0px; /* Restore default style for IE */
}

.redirect-button:hover {
  background-color: rgb(62, 26, 99);
}

.notfoundtext-content{
  width: 75vw;
}


}





/* For tablet screens (768px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {

/* Reset WebKit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none; /* Ensure the scrollbar is visible */
}

/* Reset Firefox */
html, body, * {
  scrollbar-width: 0px; /* Restore the default width */
  -ms-overflow-style:0px; /* Restore default style for IE */
}
.redirect-button:hover {
  background-color: rgb(62, 26, 99);
}

  .notfoundtext-content{
    width: 75vw;
  }
  
  
  

}













.avatar-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    overflow: visible;
    cursor: pointer;
  }
  
  .liquid-border {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: var(--fun-bg);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1;
    animation: morph 10s infinite linear;
    will-change: transform, border-radius;
  }
  
  .avatar-img {
    width:190px;
    height:190px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }
  
  @keyframes morph {
    0%, 100% {
      border-radius: 50%;
      transform: scale(1) rotate(0deg) translateY(-5px);
    }
    25% {
      border-radius: 65% 45% 55% 45% / 55% 60% 40% 50%;
      transform: scale(1.05) rotate(10deg);
    }
    50% {
      border-radius: 45% 65% 55% 55% / 60% 45% 65% 40%;
      transform: scale(1.1) rotate(20deg) translateY(5px);
    }
    75% {
      border-radius: 60% 40% 60% 50% / 50% 70% 45% 55%;
      transform: scale(1.05) rotate(10deg);
    }
  }
  



  @media (max-width: 768px) {
    .avatar-container {
      width: 140px;
      height: 140px;
    }
    
    .liquid-border {
      width: 140px;
      height: 140px;
    }
    
    .avatar-img {
      width:130px;
      height:130px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .avatar-container {
      width: 140px;
      height: 140px;
    }
    
    .liquid-border {
      width: 140px;
      height: 140px;
    }
    
    .avatar-img {
      width:130px;
      height:130px;
    }
  }
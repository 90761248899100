:root{
    --exp-font-family: "Climate Crisis", sans-serif;
    --exp-font-size-pc:50px;
    --exp-font-size-tablet:40px;
    --exp-font-size-mobile:40px;
  
    
    --kitty-item-font-family:  "Climate Crisis", sans-serif;
    --kitty-item-font-size-pc:35px;
    --kitty-item-font-size-tablet:35px;
    --kitty-item-font-size-mobile:35px;
    --kitty-item-bg-color:rgb(0, 0, 0,.5);
    --kitty-item-bg-color-2:rgb(0, 0, 0,.5);
    --kitty-item-font-color:rgb(66, 175, 175);
    --kitty-item-border:5px solid rebeccapurple;
    --kitty-item-border-2:5px solid rebeccapurple;
  
    --kitty-item-bg-color-title:rgb(159, 217, 219);
    --kitty-item-border-title:5px solid rgb(0, 0, 0);
  
    --prof-name-font-family:"Lexend Deca", sans-serif;
  
    
    --header2-font-family:"Lexend Deca", sans-serif;
  
  
  --mountain-width-pc:calc(300px/3);
  --mountain-round-pc:calc(300px/25);
  --font-family:var(--prof-name-font-family);

  /*Toggle*/
  --on-text-font-family: var(--prof-name-font-family);
  --inside-switch-font-size: 30px;

  --toggle-color: rgb(116, 33, 33);
  --toggle-border: 8px solid rgb(253, 251, 91);
  --togglr-border-radius: 50px 50px 50px 50px;
  --toggle-skew: 0deg;

  --ball-color-actual: rgba(68, 230, 208, 0.87);
  --ball-color-shadow: rgba(0, 0, 0, 0.5);
  --ball-radious: 50%;
  --ball-width: 80px;
  --ball-height: 80px;
  --ball-top-margin: -40px;
  --ball-shadow-right: 8px;


  /*Loading dotys*/
  --font-family: var(--prof-name-font-family);
  --font-color:rgb(252, 185, 185);
  --font-size:25px;
  
  --timing: 600ms;
  --jump-height: 30px;

  --dot-width: 20px;
  --dot-height: 20px;
  --dot-1-color: rgb(179, 238, 248);
  --dot-2-color: rgb(253, 255, 148);
  --dot-3-color: rgb(137, 255, 91);
  --dot-4-color: rgb(249, 134, 253);
  }


  .nerdcake-main{
    background: linear-gradient(to bottom, white,white);
    z-index: 1000;
    min-height: 100vh;
  }

  .nerdcake{
    border-radius: 25px;
    background-color: transparent;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 00px;
    margin: 0%;
    max-width: 100vw;
  }


  .maintwocardsfeline{
    border-radius: 25px;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2rem;
  }


  .felinecard{
    width: 20vw;
    height: auto;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
  }

  .commandcardfeline{
    width: 20vw;
    height: auto;
    border: 1px solid black;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.commandlinesfeline{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}



  .felinetext{
    font-family: var(--about-font-family);
    font-size: 12px;
    font-weight: 500;
    color: black;
    line-height: 1.9;
    max-width: auto;
    border-radius: 5px;
    background-color: rgba(156, 191, 255, 0.5);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }
  
  .commandtitle{
    font-family: var(--exp-font-family);
    font-weight: 100;
    font-size: 25px;
    letter-spacing: 2px;
  }

  .felinepic{
    width: 80px;
    height: 80px;
    border: 1px solid black;
    border-radius: 50%;
    margin: 20px auto;
    background-color: aquamarine;
    overflow: hidden;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .felinepic p{
    font-size: 40px;
  }


  .cakename{
    margin: 20px;
    padding: 0px;
    margin-top: 40px;
    font-family: var(--exp-font-family);
    font-weight: 100;
    background-color: transparent;
    text-align: start;
    font-size: 25px;
  }

  .cake-desc{
    font-family: var(--about-font-family);
    font-size: 15px;
    font-weight: 300;
    color: black;
    padding: 20px;
    text-align: start;
    line-height: 1.6;
    word-wrap: break-word; 
    margin: 0px auto; 
    max-width: 300px;
  }



.felinegraphcard{
    width: 50vw;
    height: auto;
    border: 1px solid black;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    margin: 30px;
    position: relative;
    background-color: white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
  }

  .graphfeline{
    width: 340px;
    height: 340px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    margin: 40px auto;
    position: relative;
  }


  .felinebar1,
  .felinebar2,
  .felinebar3,
  .felinebar4{
    width: 60px;
    border: 1px solid black;
    margin: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--about-font-family);
    font-weight: 300;
    font-size: 12px;
    padding: 3px;
    border-end-end-radius: 5px;
    border-end-start-radius: 5px;
  }


  .felinebar1{
    height: 30%;
    background: linear-gradient(to bottom, rgba(255, 252, 252, 0.5), rgba(156, 191, 255, 0.5));
  }

  .felinebar2{
    height: 80%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(156, 191, 255, 0.5));
  }

  .felinebar3{
    height: 55%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(156, 191, 255, 0.5));
  }

  .felinebar4{
    height: 10%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(156, 191, 255, 0.5));
  }

  .codepenbar {
    width: 20px;
    height: 20px;
    margin: 5px;
    margin-bottom: 10px;
    filter: brightness(0) contrast(100%);
  }
  

  .unness{
    position: absolute;
    bottom: 10px;
    text-align: center;
    font-family: var(--about-font-family);
    font-size: 15px;
    font-weight: 300;
    color: black;
    text-align: start;
    line-height: 1.6;
    margin: 0px auto; 
    max-width: 300px;
    background-color: transparent;
    padding: 3px;
  }

  .graphline{
    width: 95%;
    height: 1px;
    position: absolute;
    background-color: black;
    top: 13px;
  }


  .feline-graph-desc{
    width: 340px;
    height: auto;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 40px auto;
    position: relative;
  }

  .felinegraphtext{
    font-family: var(--about-font-family);
    font-size: 15px;
    font-weight: 300;
    color: black;
    padding: 20px;
    text-align: start;
    line-height: 1.6;
    word-wrap: break-word; 
    margin: 0px auto; 
  }


.discordbtnfeline {
  display: flex;
  align-items: center;
  font-family: var(--tooltip-font-family);
  background: none;
  color: black;
  padding: 10px;
  border-radius: 05px;
  font-size: 15px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid rgb(0, 0, 0);
  transition:0.2s ease;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
}

.discordbtnfeline:hover {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(156, 191, 255, 0.5));
  box-shadow: 0px 5px 8px rgba(38, 42, 82, 0.3);
}

.discordbtnfeline:active {
  transform: scale(0.9);
}

.gitty {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    filter: invert(1);
  }
  

  

.backhome2 {
  display: flex;
  align-items: center;
  font-family: var(--tooltip-font-family);
  background-color: rgb(255, 255, 255);
  color: black;
  height: 60px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid black;
  margin-top: 15px;
  transition: background-color 0.5s ease, transform 0.5s ease, border 0.5s ease;
  z-index: 999;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
}


.backhome2:hover {
  transform: scale(1.01);
}

.backhome2:active {
  transform: scale(0.9);
}

.arrowhead2 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  transition: transform 0.3s ease;
}


.backhome2:hover .arrowhead2 {
  transform: translateX(-20px);
}



.arrowline12{
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000000;
  border-radius: 100vh;
  transform: rotate(45deg);
  top: 6px;
  left: -50px;
}

.arrowline22{
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000000;
  border-radius: 100vh;
  transform: rotate(-45deg);
  bottom: 6px;
  left: -50px;
}

.arrowmainline2{
  position: absolute;
  height: 2px;
  width: 20px;
  background-color: #000000;
  border-radius: 100vh;
  left: -48px;
}







.feline24 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 40px auto;
  position: relative;
  color: black;
  background-color: rgba(156, 191, 255, 0.5);
  transition: background-color .2s ease-in-out;
  transform: scale(1);
  cursor: pointer;
  text-align: center;
}

.feline24:hover {
  background-color: rgba(102, 133, 190, 0.5);
}



.runningfeline{
  position: absolute;
  font-family: var(--exp-font-family);
  font-weight: 100;
  font-size: 30px;
  max-width: 60%;
  text-align: center;
  user-select: none;
}



.boundryboximg {
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 40px auto;
    position: relative;
    cursor: pointer;
    text-align: center;
  }
























@media (max-width: 768px) {


  .nerdcake{
    max-width: 100vw;
    border: none;
    gap: 5rem;
  }

  .maintwocardsfeline{
    gap: 5rem;
    border: none;
  }
  .felinecard{
    width: 85vw;
    height: auto;
  }

  .commandcardfeline{
    width: 85vw;
    height: auto;
  }

  .cakename{
    margin: 20px;
    padding: 0px;
    margin-top: 40px;
  }


.felinegraphcard{
    width: 85vw;
    height: auto;
    justify-content: center;
    box-shadow: none;
  }

  .graphfeline{
    width: 85vw;
    height: 340px;
  }


  .felinebar1,
  .felinebar2,
  .felinebar3,
  .felinebar4{
    width: 40px;
    font-weight: 500;
    font-size: 10px;
    padding: 5px;
  }


  .codepenbar {
    width: 15px;
    height: 15px;
    margin: 5px;
    margin-bottom: 10px;
    filter: brightness(0) contrast(100%);
  }
  

  .unness{
    position: absolute;
    bottom: 10px;
    text-align: center;
    font-family: var(--about-font-family);
    font-size: 15px;
    font-weight: 300;
    color: black;
    text-align: start;
    line-height: 1.6;
    margin: 0px auto; 
    max-width: 300px;
    background-color: transparent;
    padding: 3px;
  }



  .feline-graph-desc{
    width: 80vw;
    height: auto;
    border-radius: 15px;
  }

  .felinegraphtext{
    max-width: 80vw;
  }




  

.backhome2 {
  display: flex;
  align-items: center;
  right: 20vw;
  position: relative;
}







.felinegraphcard{
    border: 1px solid transparent;
  }





}
  
    
@media (min-width: 769px) and (max-width: 1024px) {



 
   .nerdcake{
     max-width: 100vw;
     border: none;
     gap: 5rem;
   }
 
   .maintwocardsfeline{
     gap: 5rem;
     border: none;
     flex-direction: row;
   }
   .felinecard{
     width: 85vw;
     height: auto;
   }
 
   .commandcardfeline{
     width: 85vw;
     height: auto;
   }
 
   .cakename{
     margin: 20px;
     padding: 0px;
     margin-top: 40px;
   }
 
   .commandlines{
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
  }
 .felinegraphcard{
     width: 85vw;
     height: auto;
     justify-content: center;
   }
 
   .graphfeline{
     width: 85vw;
     height: 340px;
   }
 
 
   .felinebar1,
   .felinebar2,
   .felinebar3,
   .felinebar4{
     width: 40px;
     font-weight: 500;
     font-size: 10px;
     padding: 5px;
   }
 
 
   .codepenbar {
     width: 15px;
     height: 15px;
     margin: 5px;
     margin-bottom: 10px;
     filter: brightness(0) contrast(100%);
   }
   
 
   .unness{
     position: absolute;
     bottom: 10px;
     text-align: center;
     font-family: var(--about-font-family);
     font-size: 15px;
     font-weight: 300;
     color: black;
     text-align: start;
     line-height: 1.6;
     margin: 0px auto; 
     max-width: 300px;
     background-color: transparent;
     padding: 3px;
   }
 
 
 
   .feline-graph-desc{
     width: 80vw;
     height: auto;
     border-radius: 15px;
   }
 
   .felinegraphtext{
     max-width: 80vw;
   }
 
 
 
 
   
 
 .backhome2 {
   display: flex;
   align-items: center;
   right: 20vw;
   position: relative;
 }


   
 .graphcard{
  border: 1px solid transparent;
}

}

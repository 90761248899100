:root{
  --yellow:rgb(255, 245, 116);
  --red:rgb(255, 131, 131);
  --blue:rgb(161, 214, 203);
  --purple:rgb(161, 154, 211);

  --alrt-font-family:"Lexend Deca", sans-serif; 
  --alrt-font-size-pc:14px;
  --alrt-font-size-tablet:14px;
  --alrt-font-size-mobile:14px;

  --alrt-bg-color:rgb(0, 0, 0,.8);
  --alrt-font-color:rgb(66, 175, 175);
  --alrt-border:5px solid rebeccapurple;

  --ftr-form-bg-color:rgb(0, 0, 0,.5);
  --ftr-form-font-color:white;
  --ftr-form-border:1px solid rebeccapurple;
  --ftr-form-placeholder:rgb(121, 121, 121);

  --ftr-bg-color:rgb(0, 0, 0);
  --ftr-font-color:white;


  --alrt-btn-color:rebeccapurple;
  --alrt-btn-hover:rgb(162, 107, 218);
  --alrt-btn-border:5px solid yellow;
  --alrt-btn-border-2:5px solid rgb(0, 195, 255);
  
  --alrt-btn-font-family:"Lexend Deca", sans-serif; 
  --alrt-btn-font-size-pc:14px;
  --alrt-btn-font-size-tablet:14px;
  --alrt-btn-font-size-mobile:14px;

  --input-font-family:"Lexend Deca", sans-serif; 
  --input-font-size-pc:24px;
  --input-font-size-tablet:20px;
  --input-font-size-mobile:20px;


  --ftr-font-family: "Climate Crisis", sans-serif;
  --ftr-font-size-pc:40px;
  --ftr-font-size-tablet:30px;
  --ftr-font-size-mobile:20px;

  --ftr-form-font-family: "Poiret One", sans-serif;
  --ftr-form-font-size-pc:25px;
  --ftr-form-font-size-tablet:25px;
  --foorm-font-size-mobile:20px;


  --tip-bg-color: rgba(31, 22, 83, 0.7);     
  --tip-bg-colornav: rgb(0, 0, 0);    
  --tip-text-color: #ffffff;  
  --tip-border-color: transparent;
  --tip-font-family: "Lexend Deca", sans-serif; 
  --tip-font-size-pc: 15px;    
  --tip-font-size-tablet: 13px;  
  --tip-font-size-mobile: 12px;  
  --tip-padding: 5px;         
  --tip-border-radius: 1px;   
  --tip-max-width: 250px;      
  --tip-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3);


  --ftr-main-bg:  
  radial-gradient(circle at top left, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle at top right, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom left, rgb(0, 5, 49,.7), transparent 50%),
  radial-gradient(circle at bottom right, rgb(78, 0, 0,.7), transparent 50%),
  radial-gradient(circle, rgb(102, 51, 153,.7), transparent 0%);color: #212529;
 

  --social-btn-border:2px solid rgb(255, 255, 255);
  --social-btn-shadow:0px 0px 10px rgba(255, 255, 255, 0.7);

  --social-filter:none;


  --toggie-font-family: var(--prof-name-font-family);
  --toggie-font-size: 20px;

  --main-toggie-color: rgb(116, 33, 33);
  --main-toggie-border: 2px solid rgb(255, 255, 255);
  --main-toggie-border-radius: 50px 50px 50px 50px;
  --main-toggie-skew: 0deg;

  --main-toggie-ball-color-actual: rgba(68, 230, 208, 0.87);
  --main-toggie-ball-color-shadow: rgba(0, 0, 0, 0.5);
  --main-toggie-ball-radious: 50%;
  --main-toggie-text-margin: 0px;
  --main-toggie-ball-width: 30px;
  --main-toggie-ball-height: 30px;
  --main-toggie-ball-top-margin: 0px;
  --main-toggie-ball-shadow-right: 0px;

}


.retro-mode-enable{

  --ftr-main-bg:linear-gradient(to bottom, rgb(75, 165, 192),rgb(188, 179, 255));
 
  --ftr-form-bg-color:rgb(255, 255, 255);
  --ftr-form-font-color:rgb(0, 0, 0);
  --ftr-form-border: 1px solid aqua;
  --ftr-font-color:rgb(0, 0, 0);
  --ftr-form-placeholder:rgb(0, 0, 0);

  --social-btn-border:2px solid rgb(0, 0, 0);
  --social-btn-shadow:0px 0px 10px rgba(0, 0, 0, 0.7);

  --social-filter:invert(1) brightness(100%);

  --main-toggie-color: rgb(68, 100, 168);
  --main-toggie-border: 2px solid rgb(253, 251, 91);
  --main-toggie-border-radius: 50px 50px 50px 50px;
  --main-toggie-skew: 0deg;

  --main-toggie-ball-color-actual: rgba(255, 130, 130, 0.87);
  --main-toggie-ball-color-shadow: rgba(0, 0, 0, 0.5);
  --main-toggie-ball-radious: 50%;
  --main-toggie-text-margin: 0px;
  --main-toggie-ball-width:30px;
  --main-toggie-ball-height: 30px;
  --main-toggie-ball-top-margin: 0px;
  --main-toggie-ball-shadow-right: 0px;
}

.swal-icon-no-bg {
  background: none !important;
  border: none !important;
}
.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
  background-color: transparent !important;
}
.quick-access{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-block: 40px;
}

.quick-access a{
  margin: 10px;
}


/*Retro Toggle*/
.color-switch-main{
  cursor: pointer;
}

/* Hide the actual checkbox */
#retro-mode-color-switch-main {
  display: none;
}

.retro-mode-toggle-main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.retro-main {
  position: relative;
  width: 80px;
  height: auto;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--main-toggie-border-radius);
  background-color: var(--main-toggie-color);
  border: var(--main-toggie-border);
 
  transition:
    border 0.7s,
    background-color 0.5s,
    transform 0.2s;
}

.ball-main {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-toggie-ball-color-actual);
  border-radius: var(--main-toggie-ball-radious);
  width: var(--main-toggie-ball-width);
  height: var(--main-toggie-ball-height);
  box-shadow: 0 5px 10px rgb(0, 0, 0,.7);
  transition: 0.5s
    linear(
      0,
      0.002 0.3%,
      0.009 0.6%,
      0.02 0.9%,
      0.04 1.3%,
      0.091 2%,
      0.168 2.8%,
      0.25 3.5%,
      0.34 4.2%,
      0.706 6.8%,
      0.854 7.9%,
      0.986 9%,
      1.097 10.1%,
      1.178 11.1%,
      1.211 11.6%,
      1.239 12.1%,
      1.262 12.6%,
      1.279 13.1%,
      1.292 13.6%,
      1.302 14.2%,
      1.305 14.8%,
      1.302 15.5%,
      1.292 16.2%,
      1.276 16.9%,
      1.252 17.7%,
      1.223 18.5%,
      1.054 22.5%,
      1.016 23.5%,
      0.983 24.5%,
      0.953 25.6%,
      0.931 26.7%,
      0.916 27.8%,
      0.908 29%,
      0.907 29.6%,
      0.908 30.3%,
      0.911 31%,
      0.916 31.8%,
      0.933 33.4%,
      0.983 37.3%,
      1.005 39.3%,
      1.014 40.4%,
      1.021 41.5%,
      1.025 42.6%,
      1.028 43.7%,
      1.028 45.1%,
      1.026 46.6%,
      0.999 54%,
      0.994 56.1%,
      0.992 58.3%,
      0.992 61.4%,
      1 68.7%,
      1.002 72.8%,
      0.999 87.2%,
      1
    );
  z-index: 1000;
}

/*ball-animation*/
#retro-mode-color-switch-main:checked + .color-switch-main .ball-main {
  transform: translateX(20px) skewX(var(--main-toggie-skew));
}

#retro-mode-color-switch-main + .color-switch-main .ball-main {
  transform: translateX(-20px);
}


/*Retro Toggle*/










/* Main tip container */
.custom-tip {
    background-color: var(--tip-bg-color) !important;
    color: var(--tip-text-color) !important;
    border: 1px solid var(--tip-border-color) !important;
    padding: var(--tip-padding) !important;
    font-family: var(--tip-font-family) !important;
    font-size: var(--tip-font-size-pc) !important;
    border-radius: var(--tip-border-radius) !important;
    max-width: var(--tip-max-width) !important;
    text-align: center;
    box-shadow: var(--tip-box-shadow) !important;
    font-weight: 200 !important;
    transition: all 0.3s ease;
  }
  
  .custom-tipnav {
    background-color: var(--tip-bg-colornav) !important;
    color: var(--tip-text-color) !important;
    border: 1px solid var(--tip-border-color) !important;
    padding: var(--tip-padding) !important;
    font-family: var(--tip-font-family) !important;
    font-size: var(--tip-font-size-pc) !important;
    border-radius: var(--tip-border-radius) !important;
    max-width: var(--tip-max-width) !important;
    text-align: center;
    box-shadow: var(--tip-box-shadow) !important;
    font-weight: 200 !important;
    transition: all 0.3s ease;
  }
  
  /* Ensure multiline tips render well */
  .custom-tip[data-multiline='true'] {
    white-space: pre-wrap; /* Preserve line breaks */
  }
  



.footer-main{
  background:var(--ftr-main-bg);
  background-color: #000000; 
  padding: 2rem;
    text-align: center;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.5);
    max-width: 100vw;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
  }
  

  .contactme {
    font-size: var(--ftr-font-size-pc);
    font-family: var(--ftr-font-family);
    font-weight: 100;
    margin-bottom: 50px;
    color: var(--ftr-font-color);
    text-shadow: 1px 5px 10px rgba(0, 0, 0, 0.3);
  }
  

  .contact-form {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    background-color: transparent; 
    padding: 0px;
    border-radius: 0px;
  }
  

  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: none;
  }
  
  .form-group input {
    width:300px ;
    max-width: 300px;
    padding: 10px;
    border: var(--ftr-form-border);
    height: 40px;
    border-radius: 5px;
    font-size:var(--ftr-form-font-size-pc);
    font-family: var(--ftr-form-font-family);
    box-sizing: border-box;
    font-weight: 800;
    background-color:var(--ftr-form-bg-color);
    color: var(--ftr-form-font-color);
  }
  
  .form-group input:focus {
    outline: none;
    border-color: var(--ftr-form-border);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
    background-color:rgb(255, 255, 255);
    color: var(--ftr-form-placeholder);
  }
  
  /* Button */
.formbtn {
  background: transparent;
  border: 1px solid black;
  color: white;
  font-family: var(--prof-name-font-family);
  background-color: rebeccapurple;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  margin-top: 15px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
}
  
  .formbtn:hover {
    background-color: rgb(62, 26, 99);
    transition: 150ms;
  }
 

  .formbtn:disabled {
    background-color: rgb(198, 177, 219);
    cursor: not-allowed;
  }
  
  /* Honeypot Field */
  .hidden-field {
    display: none; /* Completely hide the honeypot field */
  }

  .formbtn:active{
    transform:scale(0.9);
  }
  





  .social-buttons {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    background: transparent;
    margin-top: 50px;
    justify-content: center;
  }
  
  .social-button{
    background-color: transparent;
    border: none;
    padding: 10px;
  }

.social-button img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    padding: 5px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: transparent;
    transform: scale(1);
    filter: var(--social-filter);
    transition: all 0.5s linear(0, 0.011 0.8%, 0.041 1.6%, 0.158 3.3%, 0.814 9.5%, 1.04 12.1%, 1.123 13.4%, 1.185 14.7%, 1.226 16%, 1.248 17.3%, 1.252 18.9%, 1.231 20.7%, 1.19 22.6%, 1.058 27.4%, 1 29.9%, 0.958 32.6%, 0.938 35.4%, 0.941 39%, 0.999 48.1%, 1.016 53.5%, 0.996 71.3%, 1);

}

.social-button img:hover {
    opacity: 0.8;
    border: var(--social-btn-border);
    box-shadow: var(--social-btn-shadow);
    transform: scale(1.25);
}


.social-buttons .social-button:active {
    transform: scale(0.95);
    transition: transform 0.15s linear(0, 0.011 0.8%, 0.041 1.6%, 0.158 3.3%, 0.814 9.5%, 1.04 12.1%, 1.123 13.4%, 1.185 14.7%, 1.226 16%, 1.248 17.3%, 1.252 18.9%, 1.231 20.7%, 1.19 22.6%, 1.058 27.4%, 1 29.9%, 0.958 32.6%, 0.938 35.4%, 0.941 39%, 0.999 48.1%, 1.016 53.5%, 0.996 71.3%, 1);
}


.rights{
    font-family: var(--prof-name-font-family);
    font-size: 15px;
    color: var(--ftr-font-color);
    font-weight: 200;
}  














  
.alert-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    backdrop-filter: blur(10px);
    width: 100vw;
    padding: 0%;
    margin: 0%;
  }

  .alert {
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    background:   
    radial-gradient(circle at top left, rgb(78, 0, 0,.7), transparent 50%),
    radial-gradient(circle at top right, rgb(0, 5, 49,.7), transparent 50%),
    radial-gradient(circle at bottom left, rgb(0, 5, 49,.7), transparent 50%),
    radial-gradient(circle at bottom right, rgb(78, 0, 0,.7), transparent 50%),
    radial-gradient(circle, rgb(102, 51, 153,.7), transparent 100%);color: #212529;
    background-color: #000000; 
    color: white;
    font-size:var(--alrt-font-size-pc);
    text-align:center;
    padding: 25px;
    border-radius: 25px;
    border: 5px solid black;
    z-index: 9999999999;
    font-family: var(--alrt-font-family);
    font-weight: 200;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    max-width: 500px;
  }

  .alert p{
    pointer-events: none;
  }
  
  .alert button {
    background: transparent;
    border: 1px solid black;
    color: white;
    font-family: var(--prof-name-font-family);
    background-color: rebeccapurple;
    font-size: 12px;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
    margin-top: 15px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    min-width: 90px;
  }
  
  .alert button{
    transition: transform 0.2s, background-color 0.2s, color 0.2s; /* Add transition for smooth scaling and color change */
  }
  
  .alert button:active {
    transform: scale(0.9);
  }
  
  
  .alert button:focus{
      outline: rgb(255, 255, 255);
  }
  
  .alert button:hover {
    background-color: rgb(62, 26, 99);
    transition: 150ms;
  }
  

  
  















  







  
/* For mobile screens */
@media (max-width: 768px) {

    /* Reset WebKit-based browsers (e.g., Chrome, Safari) */
    ::-webkit-scrollbar {
      display: none; /* Ensure the scrollbar is visible */
    }
    
    /* Reset Firefox */
    html, body, * {
      scrollbar-width: 0px; /* Restore the default width */
      -ms-overflow-style:0px; /* Restore default style for IE */
    }
    .formbtn:hover {
      background-color: rgb(62, 26, 99);
      transition: 150ms;
    }
    
    .alert button:active {
      transform: scale(1);
    }
    
    .alert button:hover {
      background-color: rgb(62, 26, 99);
      transition: 150ms;
    }
  
    .formbtn:active{
      transform:scale(1);
    }
    
    .alert {
        font-size:var(--alrt-font-size-mobile);
        max-width: 80vw;
      }
    
      .alert button {
          font-size: var(--alrt-btn-font-size-mobile) ;
          width: auto;
      }
     
      
      .social-button img:hover {
        opacity: 1;
        border: 2px solid transparent;
        box-shadow: none;
      }
      
      .social-buttons .social-button:active {
        transform: scale(1);
      }
    
    }
    
    
    
    
    
    /* For tablet screens (768px to 1024px) */
    @media (min-width: 769px) and (max-width: 1024px) {
    
    /* Reset WebKit-based browsers (e.g., Chrome, Safari) */
    ::-webkit-scrollbar {
      display: none; /* Ensure the scrollbar is visible */
    }
    
    /* Reset Firefox */
    html, body, * {
      scrollbar-width: 0px; /* Restore the default width */
      -ms-overflow-style:0px; /* Restore default style for IE */
    }

    .formbtn:hover {
      background-color: rgb(62, 26, 99);
      transition: 150ms;
    }
   
    .alert button:active {
      transform: scale(1);
    }
    
    .alert button:hover {
      background-color: rgb(62, 26, 99);
      transition: 150ms;
    }
  
    .formbtn:active{
      transform:scale(1);
    }
    
    .alert {
        font-size:var(--alrt-font-size-tablet);
        max-width: 80vw;
      }
    
      .alert button {
          font-size: var(--alrt-btn-font-size-tablet) ;
          width: auto;
      }
    
      .social-button img:hover {
        opacity: 1;
        border: 2px solid transparent;
        box-shadow: none;
      }
      
      .social-buttons .social-button:active {
        transform: scale(1);
      }
    }
    

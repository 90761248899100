body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  padding: 0%;
  overflow-x: hidden;
}

html{
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* For webkit browsers like Chrome, Safari, Edge */
::-webkit-scrollbar {
  width: 15px;  /* Width of the vertical scrollbar */
  height: 10px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgb(39, 30, 110),rebeccapurple);
   border-radius: 0px;      /* Round the edges */
  border-inline-start: 1px solid rgb(0, 0, 0);
  border-block-end:1px solid rgb(0, 0, 0);
  border-block-start: 1px solid rgb(0, 0, 0);
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--nav-bg-color);  /* Color of the thumb when hovered */
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: black; /* Track background color */
  border-radius: 0px;        /* Round the edges of the track */
  border-inline-start: 1px solid rgb(0, 0, 0); 
  cursor: pointer;
}
* {
  -webkit-tap-highlight-color: transparent; /* for Webkit-based browsers */
}
